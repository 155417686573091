import React, { useMemo } from 'react';
import {
  HealthWarning,
  ServiceCardButton,
  PrimaryButtonLarge,
  PaymentMethods,
} from '@fingermarkglobal/cringer.components';
import DineInImage from '../../../assets/images/dinein.svg';
import TakeawayImage from '../../../assets/images/takeaway.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import Logo from '../../../assets/images/logo.svg';

const PrimaryHome = ({
  className = '',
  t = () => {},
  accessibilityEnabled = false,
  primaryAction,
  secondaryAction,
  isCurrentLang,
  card,
  cash,
  icons,
}) => {
  const titleClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-8xl' : 'text-12xl';
  }, [isCurrentLang]);

  const subTitleClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-7xl' : 'text-9xl';
  }, [isCurrentLang]);

  const isBordered = !!(icons?.length || card);
  return (
    <div className={`flex flex-col justify-between items-center h-full w-full ${className}`}>
      <div
        className="flex flex-col items-center justify-start w-full h-full mb-32"
        data-test="home-banner"
      >
        <PaymentMethods
          t={t}
          card={card}
          cash={cash}
          icons={icons}
          Logo={Logo}
          enableLogo={!accessibilityEnabled}
          className={`px-12 py-2 text-7xl  ${isBordered ? 'border-2 border-black' : ''} rounded ${
            isBordered && !accessibilityEnabled ? 'mt-24' : ''
          }`}
        />
        <div className="flex flex-col items-center justify-center mt-32 leading-none">
          <p className={`${titleClass} text-black font-extrabold`} data-test="home-banner-title">
            {t('homeTitle')}
          </p>
          <p
            className={`${subTitleClass} text-black font-extrabold`}
            data-test="home-banner-subtitle"
          >
            {t('homeSubtitle')}
          </p>
        </div>
      </div>
      <div
        className="flex flex-col-reverse items-center justify-start w-full h-full"
        data-test="home-banner"
      >
        {/* `WashHandsImage` width is exactly 109 px */}
        <HealthWarning image={WashHandsImage} t={t} className="w-4/5 mb-48" width="109" />
        <div
          className="flex items-stretch justify-center w-4/5 mb-32 space-x-16"
          data-test="home-services"
        >
          {!!secondaryAction && !accessibilityEnabled ? (
            <ServiceCardButton
              data-test="button-secondary"
              onClick={secondaryAction}
              className="w-1/2 shadow-lg"
            >
              <div className="mt-4">{t('homeSecondaryAction')}</div>
              <DineInImage className="w-64" />
            </ServiceCardButton>
          ) : null}
          {!!secondaryAction && accessibilityEnabled ? (
            <PrimaryButtonLarge
              data-test="button-secondary"
              onClick={secondaryAction}
              className="w-1/2 shadow-lg"
            >
              {t('homeSecondaryAction')}
            </PrimaryButtonLarge>
          ) : null}
          {!!primaryAction && !accessibilityEnabled ? (
            <ServiceCardButton
              data-test="button-primary"
              onClick={primaryAction}
              className="w-1/2 shadow-lg"
            >
              <div className="mt-4">{t('homePrimaryAction')}</div>
              <TakeawayImage className="w-32" />
            </ServiceCardButton>
          ) : null}
          {!!primaryAction && accessibilityEnabled ? (
            <PrimaryButtonLarge onClick={primaryAction} className="w-1/2 shadow-lg">
              {t('homePrimaryAction')}
            </PrimaryButtonLarge>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { PrimaryHome };

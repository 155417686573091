import React from 'react';
import LogoImage from '../../../assets/images/logo.svg';

const Decoration = ({ className = '', showLogo = false } = {}) => {
  return (
    <div className={`flex flex-col items-center space-y-16 pointer-events-none ${className}`}>
      <div className="w-full flex items-center justify-center">
        <div className="inline-block w-24 h-12 bg-primary"></div>
        <div className="inline-block w-24 h-12 mx-24 bg-primary"></div>
        <div className="inline-block w-24 h-12 bg-primary"></div>
      </div>
      {showLogo && <LogoImage className="w-64 h-auto " />}
    </div>
  );
};

export { Decoration };

import React from 'react';
import {
  ReceiptDetails,
  CartViewProduct,
  SecondaryButtonLarge,
  PrimaryButtonLarge,
  useReceiptView,
  ReceiptPayment,
} from '@fingermarkglobal/cringer.components';

import Leaf from '../../assets/images/leaf.svg';

const CustomReceiptView = () => {
  const {
    cart,
    sendSms,
    smsEnabled,
    receiptPaymentData,
    receiptDetailsData,
    receiptDone,
    t,
    formatPrice,
  } = useReceiptView();

  return (
    <div data-test="receipt-page" className="flex flex-col items-center h-full w-full space-y-8">
      <div className="flex justify-start items-center bg-accessory px-8 py-6 w-full h-32 mt-6">
        <div className="text-5xl font-bold uppercase">{t('receiptTitle')}</div>
        <Leaf className="w-12 ml-8 mr-4" />
        <div className="text-3xl font-bold text-leaf-green">{t('receiptEcoMessage')}</div>
      </div>
      <div className="flex flex-col items-center h-full w-full space-y-8 overflow-y-auto p-8">
        <ReceiptDetails receiptDetailsData={receiptDetailsData} t={t} boxClassName="shadow-lg" />
        {cart &&
          cart.map(product => (
            <CartViewProduct
              product={product}
              key={product.uid}
              t={t}
              boxClassName="shadow-lg"
              formatPrice={formatPrice}
            />
          ))}
      </div>
      <div className="flex flex-col items-center justify-center space-y-8 p-8 w-full">
        <ReceiptPayment receiptPaymentData={receiptPaymentData} t={t} boxClassName="shadow-lg" />
        <div className="flex flex-row items-center justify-center w-full">
          {smsEnabled && (
            <SecondaryButtonLarge className="w-1/4 mr-8 text-button-large" onClick={sendSms}>
              {t('receiptSmsAction')}
            </SecondaryButtonLarge>
          )}
          <PrimaryButtonLarge className="w-1/4" onClick={receiptDone}>
            {t('receiptPrimaryAction')}
          </PrimaryButtonLarge>
        </div>
      </div>
    </div>
  );
};

export { CustomReceiptView };

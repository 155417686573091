import React from 'react';
import { PrimaryButtonLarge, LocaleSelection } from '@fingermarkglobal/cringer.components';

const SecondaryHome = ({
  className = '',
  t = () => {},
  accessibilityEnabled = false,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  languages = [],
}) => {
  return (
    <div className={`flex flex-col justify-between items-center h-full w-full ${className}`}>
      <div
        className="flex flex-col items-center justify-start w-full h-full"
        data-test="home-banner"
      ></div>
      <div
        className="flex flex-col-reverse items-center justify-start w-full h-full"
        data-test="home-banner"
      >
        <div
          className={`flex items-stretch justify-center w-4/5 ${
            !accessibilityEnabled ? 'mb-80' : 'mb-16'
          } space-x-16`}
          data-test="home-services"
        >
          {!!secondaryAction && (
            <PrimaryButtonLarge
              data-test="button-secondary"
              onClick={secondaryAction}
              className="w-1/2"
            >
              {t('homeSecondaryAction')}
            </PrimaryButtonLarge>
          )}
          {!!primaryAction && (
            <PrimaryButtonLarge onClick={primaryAction} className="w-1/2">
              {t('homePrimaryAction')}
            </PrimaryButtonLarge>
          )}
        </div>
      </div>
      <div className="flex justify-center w-full" data-test="home-footer">
        <LocaleSelection
          languages={languages}
          onChangeLanguage={onChangeLanguage}
          className="flex items-center justify-center"
        />
      </div>
    </div>
  );
};

export { SecondaryHome };
